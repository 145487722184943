<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="내부정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline >
                <c-btn
                  v-if="editable&&isOld"
                  label="경고표지(6 x 4)"
                  icon="print"
                  @btnClicked="printWarning('s')" />
                <c-btn
                  v-if="editable&&isOld"
                  label="경고표지(11 x 9)"
                  icon="print"
                  @btnClicked="printWarning('m')" />
                <c-btn
                  v-if="editable&&isOld"
                  label="경고표지(A4)"
                  icon="print"
                  @btnClicked="printWarning('b')" />
                <c-btn
                  v-if="editable"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="chemData"
                  :mappingType="mappingType"
                  label="LBLSAVE"
                  icon="save"
                  @beforeAction="saveInfo"
                  @btnCallback="saveCallback" />
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-3">
                <c-material
                  :required="true"
                  :editable="editable && isUpdate"
                  label="자재코드"
                  name="materialCd"
                  type="codename"
                  :data.sync="chemData"
                  :plantCd="chemData.plantCd"
                  v-model="chemData.materialCd">
                </c-material>
              </div>
              <div class="col-3">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="품명"
                  name="mdmChemMaterialName"
                  v-model="chemData.mdmChemMaterialName">
                </c-text>
              </div>
              <div class="col-3">
                <c-plant
                  :required="true"
                  :editable="editable"
                  type="edit"
                  name="plantCd"
                  v-model="chemData.plantCd" />
              </div>
              <div class="col-3">
                <c-dept-multi  
                  :editable="editable"
                  label="사용부서" 
                  name="depts" 
                  v-model="chemData.depts" />
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="" :noHeaderCard="true" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-4">
                <c-vendor
                  :required="true"
                  :editable="editable"
                  :selfFlag="false"
                  label="협력사"
                  name="deliveryVendorCd"
                  v-model="chemData.deliveryVendorCd">
                </c-vendor>
              </div>
              <div class="col-4">
                <c-vendor
                  :required="true"
                  :editable="editable"
                  :selfFlag="false"
                  label="제조사"
                  name="mfgVendorCd"
                  v-model="chemData.mfgVendorCd">
                </c-vendor>
              </div>
              <div class="col-4">
                <c-select
                  :required="true"
                  codeGroupCd="USAGE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="usageCd"
                  label="용도"
                  v-model="chemData.usageCd"
                ></c-select>
              </div>
              <div class="col-4">
                <c-select
                  :required="true"
                  codeGroupCd="CHM_IN_UNIT_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="inUnitCd"
                  label="입고단위"
                  v-model="chemData.inUnitCd"
                ></c-select>
              </div>
              <div class="col-4">
                <c-text
                  :required="true"
                  :editable="editable"
                  type="number"
                  label="입고 환산계수"
                  name="inConversonFactor"
                  v-model="chemData.inConversonFactor">
                </c-text>
              </div>
              <div class="col-4">
                <c-select
                  :required="true"
                  codeGroupCd="CHM_IN_CONVERSION_UNIT_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="inConversonUnitCd"
                  label="입고 환산단위"
                  v-model="chemData.inConversonUnitCd"
                ></c-select>
              </div>
              <div class="col-4">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="제조사 연락처"
                  name="emergTel"
                  v-model="chemData.emergTel">
                </c-text>
              </div>
              <div class="col-4">
                <c-text
                  :required="true"
                  :editable="editable"
                  label="제조사주소"
                  name="makeAddress"
                  v-model="chemData.makeAddress">
                </c-text>
              </div>
              <div class="col-4">
                <c-text
                  :editable="editable"
                  label="비중"
                  name="specificGravity"
                  v-model="chemData.specificGravity">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="" :noHeaderCard="true" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-4">
                <c-select
                  codeGroupCd="PROPERTIES_STATE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="propertiesStateCd"
                  label="성상"
                  v-model="chemData.propertiesStateCd"
                ></c-select>
              </div>
              <div class="col-4">
                <c-text
                  :editable="editable"
                  label="색상"
                  name="colorClass"
                  v-model="chemData.colorClass">
                </c-text>
              </div>
              <div class="col-4">
                <c-select
                  codeGroupCd="WAREHOUSING_TYPE_CD"
                  type="edit"
                  itemText="codeName"
                  itemValue="code"
                  name="warehousingTypeCd"
                  label="입고형태"
                  v-model="chemData.warehousingTypeCd"
                ></c-select>
              </div>
              <div class="col-4">
                <c-text
                  :editable="editable"
                  label="폭발한계상한하한"
                  name="explosionLimit"
                  v-model="chemData.explosionLimit">
                </c-text>
              </div>
              <div class="col-4">
                <c-text
                  :editable="editable"
                  label="어는점"
                  name="freezingPoint"
                  v-model="chemData.freezingPoint">
                </c-text>
              </div>
              <div class="col-4">
                <c-text
                  :editable="editable"
                  label="끓는점"
                  name="boilPoint"
                  v-model="chemData.boilPoint">
                </c-text>
              </div>
              <div class="col-4">
                <c-text
                  :editable="editable"
                  label="인화점"
                  name="flashPoint"
                  v-model="chemData.flashPoint">
                </c-text>
              </div>
              <div class="col-4">
                <c-text
                  :editable="editable"
                  label="발화점"
                  name="firePoint"
                  v-model="chemData.firePoint">
                </c-text>
              </div>
              <div class="col-4">
                <c-text
                  :editable="editable"
                  label="증기압"
                  name="vaporPressure"
                  v-model="chemData.vaporPressure">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="" :noHeaderCard="true" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-4">
                <c-text
                  :editable="editable"
                  label="노출기준TWA"
                  name="twaStandart"
                  v-model="chemData.twaStandart">
                </c-text>
              </div>
              <div class="col-4">
                <c-text
                  :editable="editable"
                  label="특성치(경구경치흡입제외)"
                  name="characteristicValue"
                  v-model="chemData.characteristicValue">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
          <c-card title="" :noHeaderCard="true" class="cardClassDetailForm">
            <template slot="card-detail">
              <div class="col-4">
                <c-chem-material 
                  :editable="editable"
                  type="edit"
                  label="이전화학자재 품명"
                  name="preMdmChemMaterialId"
                  v-model="chemData.preMdmChemMaterialId"
                />
              </div>
              <div class="col-4">
                <c-checkbox
                  :editable="editable"
                  :isFlag="true"
                  label="LBLUSEFLAG"
                  name="useFlag"
                  v-model="chemData.useFlag"
                />
              </div>
              <div class="col-4" v-show="chemData.useFlag == 'N'">
                <c-text
                  :editable="editable"
                  label="미사용 사유"
                  name="unusedReason"
                  v-model="chemData.unusedReason">
                </c-text>
              </div>
            </template>
          </c-card>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'chem-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        mdmChemMaterialId: '',
        subtabName: '',
        plantCd: null,
      }),
    },
    chemData: {
      type: Object,
      default: () => ({
        plantCd: null,  // 사업장코드
        mdmChemMaterialId: '',  // 화학자재 일련번호
        mdmChemMaterialMsdsId: '',
        revisionNum: '',
        groupId: '',
        sysRevision: 1,
        revisionContent: '제정',
        preMdmChemMaterialId: '',  // 이전 화학자재 일련번호
        materialCd: '',  // 자재코드
        mdmChemMaterialName: '',  // 화학자재 품명
        deliveryVendorCd: '',  // 협력사명
        mfgVendorCd: '',  // 제조사명
        usageCd: null,  // 용도
        usageEtc: '',  // 용도 기타
        inUnitCd: null,  // 입고단위
        inConversonFactor: '',  // 입고환산계수
        inConversonUnitCd: '',  // 입고환산단위  CHM_IN_CONVERSION_UNIT_CD
        msdsNo: '',  // MSDS번호
        emergTel: '',  // 긴급연락처
        makeAddress: '',  // 제조사주소
        specificGravity: '',  // 비중
        propertiesStateCd: null,  // 성상
        colorClass: '',  // 색상
        warehousingTypeCd: null,  // 입고형태
        freezingPoint: '',  // 어는점
        boilPoint: '',  // 끓는점
        flashPoint: '',  // 인화점
        firePoint: '',  // 발화점
        explosionLimit: '',  // 폭발한계상한하한
        vaporPressure: '',  // 증기압
        twaStandart: '',  // 노출기준TWA
        characteristicValue: '',  // 특성치(경구경치흡입제외)
        remarks: '',  // 비고
        useFlag: 'Y',  // 사용여부
        unusedReason: '', // 미사용 사유
        regUserId: '',  // 등록자 ID
        chgUserId: '',  // 수정자 ID
        depts: '', // 사용반
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      isUpdate: true,
      saveUrl: '',
      mappingType: 'POST',
      printUrl: '',
      insertUrl: '',
      updateUrl: '',
      checkUrl: '',
      editable: true,
      isSave: false,
      updateMode: false,
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '50%',
        param: {},
        closeCallback: null,
      },
      mixFlagItems: [
        { code: 'Y', codeName: '혼합' },
        { code: 'N', codeName: '단일' },
      ],
      cmrFlagItems: [
        { code: '1A', codeName: '1A' },
        { code: '1B', codeName: '1B' },
        { code: '2', codeName: '2' },
      ],
      gradeItems: [
        { code: '4', codeName: '최대' },
        { code: '3', codeName: '대' },
        { code: '2', codeName: '중' },
        { code: '1', codeName: '소' },
      ],
      formItems: [
        { code: '분진', codeName: '분진(㎎/㎥)' },
        { code: '증기', codeName: '증기(ppm)' },
      ],
    };
  },
  computed: {
    isOld() {
      return Boolean(this.popupParam.mdmChemMaterialId)
    }
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.saveUrl = transactionConfig.mdm.mam.chem.insert.url;
      this.printUrl = selectConfig.mdm.mam.chem.print.url;
      this.saveUrl = transactionConfig.mdm.mam.chem.insert.url;
      this.checkUrl = selectConfig.mdm.mam.chem.check.url;
    },
    saveInfo() {
      if (this.popupParam.mdmChemMaterialId) {
        this.saveUrl = transactionConfig.mdm.mam.chem.update.url;
        this.mappingType = 'PUT';
      } else {
        this.saveUrl = transactionConfig.mdm.mam.chem.insert.url;
        this.mappingType = 'POST';
      }

      this.$refs['editForm'].validate().then(_result => {
        if (_result) {
          let message = 'MSGSAVE';
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: message,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.chemData.regUserId = this.$store.getters.user.userId;
              this.chemData.chgUserId = this.$store.getters.user.userId;
              if (this.chemData.useFlag == 'Y') {
                this.chemData.unusedReason = '';
              }
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          window.getApp.$emit('APP_VALID_ERROR');
        }
      });
    },
    saveCallback(result) {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      if (this.mappingType == 'POST') {
        console.log('result.data',result.data)
        this.$set(this.popupParam, 'mdmChemMaterialId', result.data.mdmChemMaterialId)
        this.$set(this.popupParam, 'plantCd', result.data.plantCd)
      }
      this.$emit('getDetail');
    },
    changeCMR() {
      if (this.chemData.specialCtype !== null || this.chemData.specialMtype !== null || this.chemData.specialRtype !== null) {
        this.chemData.cmrFlag = 'Y';
      } else {
        this.chemData.cmrFlag = 'N';
      }
    },
    openReview() {
      this.popupOptions.title = '사전검토 완료 정보 검색';
      this.popupOptions.param = {
        type: 'single'
      };
      this.popupOptions.target = () => import(`${'@/pages/mdm/mam/review/chemReviewPop.vue'}`);
      this.popupOptions.width = '60%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeReviewPopup;
    },
    closeReviewPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        if (data[0].materialCd.indexOf('TEMP') === 0) {
          // 등록되지 않은 자재
          window.getApp.$emit('CONFIRM', {
            title: 'LBLCONFIRM',
            message: `등록되지 않은 자재로 화학자재 저장시 자재마스터에 저장됩니다.
(자재코드 : ${this.$_.replace(data[0].materialCd, 'TEMP', '')})`,
            // TODO : 필요시 추가하세요.
            type: 'info', // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              this.$_.extend(this.chemData, data[0])
            },
            // 취소 callback 함수
            cancelCallback: () => {
            },
          });
        } else {
          // 등록된 자재
          if (data[0].materialCount > 0) {
            // 자재코드로 등록된 화학자재가 있는 경우
            window.getApp.$emit('CONFIRM', {
              title: 'LBLCONFIRM',
              message: `해당 자재로 등록된 화학자재가 있습니다.
진행하시겠습니까?
(자재코드 : ${data[0].materialCd})`,
              // TODO : 필요시 추가하세요.
              type: 'info', // success / info / warning / error
              // 확인 callback 함수
              confirmCallback: () => {
                this.$_.extend(this.chemData, data[0])
              },
              // 취소 callback 함수
              cancelCallback: () => {
              },
            });
          } else {
            // 자재코드로 등록된 화학자재가 없는 경우
            this.$_.extend(this.chemData, data[0])
          }
        }
      }
    },
    printWarning(size) {
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.popupParam.mdmChemMaterialId);
      this.$http.type = 'GET';
      this.$http.param = {
        size: size
      };
      this.$http.request(
        _result => {
          let sizeTitle = size === 's' ? '6 x 4' : (size === 'b' ? 'A4' : '11 x 9');
          let fileOrgNm = this.chemData.materialName + '_' + sizeTitle + '.xlsx';
          let blob = thisVue.$comm.base64ToBlob(_result.data);
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
          } else {
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = fileOrgNm;
            link.click();
          }
        }, () => { }
      );
    }
  }
};
</script>